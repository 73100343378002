/* You can add global styles to this file, and also import other style files */
$primary: #3dae2b !default;
$success: #8de216 !default;
$info: #0055b8 !default;
$warning: #ffb732 !default;
$danger: #ff3b2d !default;

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

html,
body {
  height: 100%;
}

table {
  border-radius: 4px;
}

.table> :not(:first-child) {
  border-top: none;
}

// Badges
.badge.badge-outlined {
  background: white !important;
  border: 1px solid;
}

.badge.badge-outlined.badge-default {
  border-color: #b0bec5;
  color: #b0bec5;
}

.badge.badge-outlined.badge-primary {
  border-color: $primary;
  color: $primary;
}

.badge.badge-outlined.bg-secondary {
  border-color: #323a45;
  color: #323a45;
}

.badge.badge-outlined.bg-success {
  background-color: white;
  border-color: $success;
  color: $success;
}

.badge.badge-outlined.bg-warning {
  border-color: $warning;
  color: $warning;
}

.badge.badge-outlined.bg-info {
  border-color: $info;
  color: $info;
}

.badge.badge-outlined.bg-danger {
  border-color: $danger;
  color: $danger;
}

.navbar-nav .dropdown-menu {
  position: absolute;
  right: 0;
  top: 10;
  left: auto;
}

.table thead {
  border: 1px;
  border-radius: 10px;
}

.table thead tr th {
  background-color: #3dae2b;
  color: white;
}

table thead,
table tfoot {
  position: sticky;
}

table thead {
  inset-block-start: 0;
  /* "top" */
}

table tfoot {
  inset-block-end: 0;
  /* "bottom" */
}

thead th:first-child {
  border-radius: 30px 0 0 30px;
}

thead th:last-child {
  border-radius: 0 30px 30px 0;
}

.input-group input,
select {
  border-radius: 30px 0px 0px 30px;
  background-color: #e5effc;
  border: none;
  color: #0055b8 !important;
}

select {
  border-radius: 30px !important;
  background-color: #e5effc !important;
  border: none !important;
  font-weight: 600 !important;
  color: #0055b8 !important;
}

input {
  color: #0055b8 !important;
  font-weight: 600 !important;
  padding-left: 10px !important;
}

.input-group button {
  border-radius: 0px 30px 30px 0px;
  border: none;
}

.rounded-left {
  border-radius: 30px 0px 0px 30px !important;
  background-color: #e5effc;
  border: none;
}

.rounded-right {
  border-radius: 0px 30px 30px 0px !important;
  background-color: #e5effc;
  border: none;
}

.rounded {
  border-radius: 30px 30px 30px 30px !important;
  border: none;
}

.relative {
  position: relative;
  height: 100%;
}

.bottom-bar {
  background-color: white;
  padding: 0.5rem;
  border-top: 1px solid #0000001a;
}

.ng-dropdown-panel {
  background-color: white;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  border-radius: 8px;

  .ng-option {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: #323a45;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.ng-value {
  background-color: #ffffff;
  border-radius: 8px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-right: 0.25rem;
}

.toast-container> :not(:last-child) {
  margin-bottom: 0px;
}